import { ref, reactive, watch, computed } from 'vue';
import debounce from '@/utils/debounce';
import { getHeader, medicalTestsUrl } from '@/config/config';
import { sortAlphabetically } from '@/utils/sort';

export const DEFAULT_ITEMS_PER_PAGE = 25;
export const ACTIVE = 'active';
export const FEATURED_MODES = {
  all: 'all',
  featured: 'featured',
  nonFeatured: 'nonfeatured',
};

const SPECIALTIES_ENDPOINT = medicalTestsUrl + 'specialties';
const GROUPS_ENDPOINT = medicalTestsUrl + 'groups';
const COLLECTIONS_ENDPOINT = medicalTestsUrl + 'collections';
const SUBGROUPS_ENDPOINT = medicalTestsUrl + 'subgroups';
const actsEndpoint = (professionalId, isAdmin) => {
  if (isAdmin) {
    return `${medicalTestsUrl}acts`;
  }

  return `${medicalTestsUrl}professionals/${professionalId}/acts`;
};

export function useNomenclator(professionalId, isList = false, isAdmin = false) {
  const refreshActs = ref(false);
  const page = ref(1);
  const pageCount = ref(0);
  const total = ref(0);
  const itemsPerPage = ref(DEFAULT_ITEMS_PER_PAGE);
  const searched = ref(false);

  const filter = reactive({
    specialtyId: '',
    groupId: '',
    collectionId: '',
    subgroupId: '',
    featuredMode: FEATURED_MODES.all,
    query: '',
  });

  const specialties = ref([]);
  const groups = ref([]);
  const collections = ref([]);
  const subGroups = ref([]);
  const acts = ref([]);
  const selectedActs = ref([]);

  const isLoadingSpecialties = ref(false);
  const isLoadingGroups = ref(false);
  const isLoadingCollections = ref(false);
  const isLoadingSubGroups = ref(false);
  const isLoadingActs = ref(false);

  const fetchSpecialities = async () => {
    const url = new URL(SPECIALTIES_ENDPOINT);
    const params = {
      status: ACTIVE,
    };

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    const body = await raw.json();
    return body.specialties;
  };
  const fetchGroups = async specialtyId => {
    const url = new URL(GROUPS_ENDPOINT);
    const params = {
      status: ACTIVE,
      specialtyId: specialtyId,
    };

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    const body = await raw.json();
    return body.groups;
  };
  const fetchCollections = async groupId => {
    const url = new URL(COLLECTIONS_ENDPOINT);
    const params = {
      status: ACTIVE,
      groupId: groupId,
    };

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    const body = await raw.json();
    return body.collections;
  };
  const fetchSubGroups = async (collectionId, featuredMode = false) => {
    const url = new URL(SUBGROUPS_ENDPOINT);
    const params = {
      status: ACTIVE,
      collectionId: collectionId,
    };

    if (featuredMode) {
      params.featuredMode = filter.featuredMode;
    }

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    const body = await raw.json();
    return body.subgroups;
  };

  const fetchAllGroups = async () => {
    const url = new URL(GROUPS_ENDPOINT);
    const params = {
      status: ACTIVE,
    };

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    const body = await raw.json();
    return body.groups;
  };

  const fetchAllSubGroups = async (featuredMode = false) => {
    const url = new URL(SUBGROUPS_ENDPOINT);
    const params = {
      status: ACTIVE,
    };

    if (featuredMode) {
      params.featuredMode = filter.featuredMode;
    }

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    const body = await raw.json();
    return body.subgroups;
  };

  const fetchActs = async filter => {
    const url = new URL(actsEndpoint(professionalId, isAdmin));
    let params = {};

    if (filter.specialtyId) {
      params = {};
      params.specialtyId = filter.specialtyId;
    }
    if (filter.groupId) {
      params = {};
      params.groupId = filter.groupId;
    }
    if (filter.collectionId) {
      params = {};
      params.collectionId = filter.collectionId;
    }
    if (filter.subgroupId) {
      params = {};
      params.subgroupId = filter.subgroupId;
    }
    if (filter.featuredMode) {
      params.featuredMode = filter.featuredMode;
    }
    if (filter.query) {
      params.search = filter.query;
    }

    params.status = ACTIVE;
    params.page = page.value;
    params.size = itemsPerPage.value;

    url.search = new URLSearchParams(params).toString();

    const raw = await fetch(url, {
      headers: getHeader(),
    });
    return raw.json();
  };

  const loadSpecialties = async () => {
    isLoadingSpecialties.value = true;
    const response = await fetchSpecialities();
    sortAlphabetically(response, 'description');
    specialties.value = response;
    isLoadingSpecialties.value = false;
  };

  const loadGroups = async specialtyId => {
    isLoadingGroups.value = true;
    try {
      const response = await fetchGroups(specialtyId);
      sortAlphabetically(response, 'description');
      groups.value = response;
    } finally {
      isLoadingGroups.value = false;
    }
  };

  const loadCollections = async groupId => {
    isLoadingCollections.value = true;
    try {
      const response = await fetchCollections(groupId);
      sortAlphabetically(response, 'description');
      collections.value = response;
    } finally {
      isLoadingCollections.value = false;
    }
  };

  const loadSubGroups = async collectionId => {
    isLoadingSubGroups.value = true;
    try {
      const response = await fetchSubGroups(collectionId, filter.featuredMode);
      sortAlphabetically(response, 'description');
      subGroups.value = response;
    } finally {
      isLoadingSubGroups.value = false;
    }
  };

  const loadAllSubGroups = async () => {
    isLoadingSubGroups.value = true;
    try {
      const response = await fetchAllSubGroups(filter.featuredMode);
      sortAlphabetically(response, 'description');
      subGroups.value = response;
    } finally {
      isLoadingSubGroups.value = false;
    }
  };

  const loadAllGroups = async () => {
    isLoadingGroups.value = true;
    try {
      const response = await fetchAllGroups();
      sortAlphabetically(response, 'description');
      groups.value = response;
    } finally {
      isLoadingGroups.value = false;
    }
  };

  const cleanSpecialty = () => {
    filter.specialtyId = '';
  };

  const cleanGroup = () => {
    filter.groupId = '';
  };

  const cleanGroups = () => {
    filter.groupId = '';
    groups.value = [];
  };

  const cleanCollections = () => {
    filter.collectionId = '';
    collections.value = [];
  };

  const cleanSubGroups = () => {
    filter.subgroupId = '';
    subGroups.value = [];
  };

  const setSubGroup = subGroup => {
    filter.subgroupId = subGroup;
  };

  const setFeatureMode = featuredMode => {
    filter.featuredMode = featuredMode;
  };

  const setItemsPerPage = value => {
    itemsPerPage.value = value;
  };

  const loadActs = async () => {
    isLoadingActs.value = true;
    searched.value = false;
    acts.value = [];
    try {
      const response = await fetchActs(filter);
      let responseActs = response.acts;
      sortAlphabetically(responseActs, 'description');

      total.value = response.meta.total;

      responseActs = responseActs.map(act => {
        act.selected = !!selectedActs.value.find(selectedAct => selectedAct.id === act.id);
        return act;
      });

      acts.value = responseActs;
    } finally {
      isLoadingActs.value = false;
      searched.value = true;
    }
  };

  const removeAct = toRemoveAct => {
    acts.value = acts.value.filter(act => {
      return act.id !== toRemoveAct.id;
    });
  };

  const activeSpecialities = computed(() => {
    return specialties.value;
  });

  const activeGroups = computed(() => {
    return groups.value;
  });

  const activeCollections = computed(() => {
    return collections.value;
  });

  const activeSubGroups = computed(() => {
    return subGroups.value;
  });

  const activeActs = computed(() => {
    return acts.value;
  });

  const isLoading = computed(() => {
    return [isLoadingSpecialties, isLoadingGroups, isLoadingCollections, isLoadingSubGroups, isLoadingActs].some(
      loading => loading
    );
  });

  const paginationLength = computed(() => {
    if (total.value < itemsPerPage.value) {
      return 1;
    }

    return Math.trunc(total.value / itemsPerPage.value);
  });

  watch(
    () => filter.specialtyId,
    currentValue => {
      cleanGroups();
      cleanCollections();
      cleanSubGroups();

      if (currentValue) {
        loadGroups(currentValue);
      }

      if (isList) {
        loadActs();
      }
    }
  );

  watch(
    () => filter.groupId,
    currentValue => {
      cleanCollections();
      cleanSubGroups();
      if (currentValue) {
        loadCollections(currentValue);

        if (isList) {
          loadActs();
        }
      }
    }
  );

  watch(
    () => filter.collectionId,
    currentValue => {
      cleanSubGroups();
      if (currentValue) {
        loadSubGroups(currentValue);
        if (isList) {
          loadActs();
        }
      }
    }
  );

  watch(
    () => filter.subgroupId,
    currentValue => {
      if (currentValue) {
        loadActs();
      }
    }
  );

  watch(
    () => filter.query,
    () => {
      if (refreshActs.value) {
        debounce(() => {
          page.value = 1;
          pageCount.value = 0;
          total.value = 0;
          return loadActs();
        }, 500);
      } else {
        debounce(() => {
          if (filter.query) {
            page.value = 1;
            pageCount.value = 0;
            total.value = 0;

            return loadActs();
          } else {
            acts.value = [];
            searched.value = false;
          }
        }, 500);
      }
    }
  );

  watch(
    () => page.value,
    () => {
      loadActs();
    }
  );

  return {
    filter,

    acts,
    isLoading,
    isLoadingActs,
    isLoadingSpecialties,
    isLoadingGroups,
    isLoadingCollections,
    isLoadingSubGroups,

    page,
    pageCount,
    itemsPerPage,

    activeSpecialities,
    activeCollections,
    activeGroups,
    activeSubGroups,
    activeActs,

    loadSpecialties,
    loadGroups,
    loadCollections,
    loadSubGroups,
    loadAllSubGroups,
    loadAllGroups,

    setItemsPerPage,
    setFeatureMode,
    setSubGroup,

    loadActs,

    cleanCollections,
    selectedActs,
    paginationLength,
    cleanSpecialty,
    cleanGroup,
    removeAct,
    refreshActs,
    searched,
  };
}
