export const sortAlphabetically = (elements, sortByKey) => {
  return elements.sort(function(a, b) {
    if (a[sortByKey] < b[sortByKey]) {
      return -1;
    }
    if (a[sortByKey] > b[sortByKey]) {
      return 1;
    }
    return 0;
  });
};
